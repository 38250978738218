<template>
  <div class="b-no-list-card">
    <div class="b-no-list-card-left">
      <b-overlay id="overlay-background" :show="attrs['show']" variant="light" :opacity="0.8" blur="2px" rounded="sm">
        <slot name="leftBody" />
      </b-overlay>
    </div>
    <div class="b-no-list-card-right">
      <div class="b-no-list-card-header">
        <div class="d-flex align-items-center">
          <i class="fad fa-bars fa-2x toggle-list-menu" />
          <h5>{{ attrs['title'] }}</h5>
        </div>

        <b-button-group>
          <b-button
            squared
            size="sm"
            :variant="`outline-${attrs['collapse'] ? 'danger' : 'success'}`"
            @click="attrs['collapse'] = !attrs['collapse']"
          >
            {{ attrs['collapse'] ? '-' : '+' }}
          </b-button>
          <b-button squared size="sm" variant="primary" @click="$emit('newAdd', true)" v-if="attrs['newAddActive']">
            <i class="fad fa-plus" /> YENİ KAYIT
          </b-button>
        </b-button-group>
      </div>
      <div class="b-no-list-card-overlay" />
      <div class="b-no-list-card-body">
        <slot name="rightBody" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, useAttrs, onMounted } from '@vue/composition-api';

export default defineComponent({
  inheritAttrs: false,
  setup() {
    const expo = {};
    expo.attrs = useAttrs();
    expo.search = ref(null);
    expo.collapseMenu = ref(false);

    onMounted(() => {
      const listCardLeft = document.querySelector('.b-no-list-card-left');
      const toggleMenu = document.querySelector('.toggle-list-menu');
      const listCardOverlay = document.querySelector('.b-no-list-card-overlay');

      toggleMenu.addEventListener('click', toogleNav);
      listCardOverlay.addEventListener('click', toogleNav);
      function toogleNav() {
        listCardOverlay.classList.toggle('active');
        listCardLeft.classList.toggle('open-menu');
      }
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
