<template>
  <div class="h-100 w-100">
    <BNoHeader
      :title="selectMenuItem"
      :newAddActive="true"
      :route="route.name"
      :collapse="true"
      :show="show"
      :leftStyle="{ width: '450px' }"
      @newAdd="handlerNewAdd($event)"
    >
      <template v-slot:leftBody>
        <ul class="menu-list-group">
          <li class="menu-list-group-item" v-for="(item, index) in menus" :key="index">
            <router-link :to="{ name: item.route }">
              <i v-if="item.icon" :class="`fad fa-${item.icon}`" />
              {{ item.title }}
            </router-link>
          </li>
        </ul>
      </template>
      <template v-slot:rightBody>
        <transition :name="transitionEffect" mode="out-in">
          <router-view @show="show = $event" :show="show" :newAdd="newAdd" @closeEvent="newAdd = $event" />
        </transition>
      </template>
    </BNoHeader>
  </div>
</template>

<script>
import BNoHeader from '@/components/cards/BNoHeader.vue';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useRouter } from '@/libs/utils';
import store from '@/store';
export default defineComponent({
  components: {
    BNoHeader,
  },
  setup() {
    const expo = {};
    const { route } = useRouter();
    expo.route = route;
    expo.show = ref(false);
    expo.newAdd = ref(false);
    expo.routeTitle = ref(null);
    expo.menus = ref([
      {
        title: 'Tarife Bölgeleri',
        route: 'tarife-bolgeleri',
        icon: 'map-marker-exclamation',
      },
      {
        title: 'Araçlar',
        route: 'araclar',
        icon: 'cars',
      },
      {
        title: 'Ayarlar',
        route: 'transfer-ayarlar',
        icon: 'cogs',
      },
    ]);

    expo.transitionEffect = computed(() => store.getters.getTransitionEffect);
    expo.selectMenuItem = ref(expo.menus.value.find((x) => x.route == route.value.name).title);

    expo.handlerNewAdd = (event) => {
      expo.newAdd.value = event;
    };

    watch(route, (val) => {
      expo.selectMenuItem.value = expo.menus.value.find((x) => x.route == val.name).title;
    });
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.menu-list-group {
  padding: 10px;
  border-radius: 0;
  .menu-list-group-item {
    position: relative;
    border: none;
    padding: 0px;
    margin: 5px;
    display: block;
    justify-content: space-between;
    align-items: center;
    a {
      color: #4d4d4d;
      font-size: 18px;
      font-weight: 500;
      display: block;
      padding: 15px 10px;
      transition: all 0.2s ease-in-out;
      i {
        padding-right: 10px;
      }
      &:hover {
        transition: all 0.2s ease-in-out;
        background-color: rgba(0, 0, 0, 0.03);
      }
      &.router-link-active {
        transition: all 0.2s ease-in-out;
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }
}
</style>
